import * as React from "react";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import Gallery from "../components/Gallery";

const PressPage = () => (
  <Layout descriptionText={"Press"}>
    <Seo title="press" />

    <Gallery categoryName="press" />
  </Layout>
);

export default PressPage;
